import * as React from "react"
import styled from "styled-components"

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
}

const RawButton = ({ children, isLoading, ...props }: ButtonProps) => {
  return (
    <button disabled={isLoading} {...props}>
      {children}
    </button>
  )
}

export const Button = styled(RawButton)`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.greenSuperDark};
  outline: none;
  border: none;
  padding: 15px 20px;
  border-radius: 10px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.greenExtraDark};
  }

  &:disabled {
    pointer-events: none;
    background-color: ${({ theme }) => theme.color.greyDark};
  }
`
