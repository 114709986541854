import React from "react"
import styled, { keyframes } from "styled-components"

export const DotsLoader = () => {
  return (
    <LoderContainer>
      <DotsContainer>
        <Dot />
        <Dot />
        <Dot />
      </DotsContainer>
    </LoderContainer>
  )
}

const DotsContainer = styled.div`
  display: flex;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
`

const LoderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
`

const move = keyframes`
  0% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(6px);
  }
  70% {
    transform: translateY(0);
  }
`

const Dot = styled.div`
  position: relative;
  box-sizing: border-box;
  float: none;
  background-color: ${({ theme }) => theme.color.greenSuperDark};
  border: 0 solid ${({ theme }) => theme.color.greenSuperDark};
  width: 12px;
  height: 12px;
  margin: 6px;
  border-radius: 100%;
  animation: ${move} 1s infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: -0.14s;
  }
  &:nth-child(2) {
    animation-delay: -0.07s;
  }
  &:nth-child(3) {
    animation-delay: 0;
  }
`
