import * as React from "react"
import styled from "styled-components"
import { useQueryParam, StringParam } from "use-query-params"

import successIcon from "assets/icons/success.svg"
import failIcon from "assets/icons/fail.svg"
import { Button } from "components/molecules/button"
import { useRegistrationConfirm } from "queries"
import { DotsLoader } from "components/molecules/dots-loader"

const VerificatiePage = () => {
  const [isInitial, setInitial] = React.useState(true)
  const { mutate, isLoading, isError, isSuccess } = useRegistrationConfirm()
  const [queryParam] = useQueryParam("verification_key", StringParam)

  const handleConfirm = () => {
    const payload = {
      verification_key: queryParam,
    }
    mutate(payload)
  }

  React.useEffect(() => {
    if (isInitial && (isSuccess || isError)) {
      setInitial(false)
    }
  }, [isSuccess, isError])

  React.useEffect(() => {
    setTimeout(() => handleConfirm(), 1000)
  }, [])

  return !isInitial || isSuccess || isError ? (
    <Container>
      <Column>
        {isSuccess ? (
          <>
            <Title>Gefeliciteerd!</Title>
            <IconWrapper>
              <img src={successIcon} alt="" />
            </IconWrapper>
            <SubTitle>Jouw account is vanaf nu actief!</SubTitle>
          </>
        ) : (
          <>
            <Title>Oeps!</Title>
            <IconWrapper>
              <img src={failIcon} alt="" />
            </IconWrapper>
            <SubTitle>Er is iets misgegaan</SubTitle>
            <Button onClick={handleConfirm} isLoading={isLoading}>
              Probeer het nog een keer
            </Button>
          </>
        )}
      </Column>
    </Container>
  ) : (
    <DotsLoader />
  )
}
const Container = styled.div`
  position: relative;
  text-align: center;
`

const Column = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    width: 60%;
    max-width: 700px;
    background-color: white;
    padding: 50px 30px;
    border-radius: 10px;
    z-index: 1;
  }
`

const IconWrapper = styled.div`
  z-index: 1;
  @media (max-width: 768px) {
    width: 130px;
  }
  @media (min-width: 768px) {
    width: 170px;
  }
  svg {
    max-width: 100%;
  }
`
const SubTitle = styled.p`
  font-weight: 600;
  padding: 0 20px;
  color: ${({ theme }) => theme.color.black};
  font-size: 16px;
  margin-bottom: 30px;
`

const Title = styled.p`
  padding: 0 20px;
  color: ${({ theme }) => theme.color.black};
  font-weight: 600;
  font-size: 30px;
`

export default VerificatiePage
