import { apiUrls } from "consts/urls"
import { useMutation } from "react-query"

import { request } from "./request"

export interface RegistrationData {
  verification_key: string
}

export const useRegistrationConfirm = () => {
  return useMutation<unknown, unknown, RegistrationData>(data => {
    return request().post(apiUrls.REGISTRATION_CONFIRM, data)
  })
}
